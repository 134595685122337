$blue-color: #39e0e8;
$darkblue-color: #0e667c;
$pink-color: #a13858;
@function p2v($px) {
	@return $px / 750 * 100vw;
}
@function p2f($px) {
	@return ($px / 16) rem;
}
html,
body {
	font-size: 16px;
}
@mixin min_header($c1: #333, $c2: #888888) {
	text-align: center;
	.title {
		color: $c1;
		font-size: 1.5rem;
	}
	.explain {
		color: $c2;
		font-size: 1rem;
	}
}
@mixin min_flex($direction: row, $justify: space-between, $align: center) {
	display: flex;
	flex-direction: $direction;
	justify-content: $justify;
	align-items: $align;
}
%min_grid-text {
	@include min_flex(column, space-around, center);
	font-size: 0.8rem;
	color: #999999;
	line-height: p2v(36);
}
header {
	display: none;
}
.min-header {
	width: 100vw;
	height: p2v(516);
	display: block;
	position: relative;
	.company-banner {
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.company-header {
		position: fixed;
		width: 100vw;
		height: p2v(90);
		top: 0;
		left: 0;
		.company-icon {
			position: absolute;
			width: p2v(206);
			height: p2v(56);
			top: p2v(20);
			left: p2v(30);
			img {
				width: 100%;
				height: 100%;
			}
		}
		.drawer-button {
			position: absolute;
			width: p2v(34);
			height: p2v(34);
			top: p2v(30);
			right: p2v(30);
			img {
				width: 100%;
				height: 100%;
			}
		}
		&.header-active {
			background-color: #fff;
			z-index: 2;
		}
	}

	.action-list {
		position: absolute;
		bottom: 0;
		width: 100%;
		@include min_flex();
		li {
			width: 33.33%;
			text-align: center;
			height: p2v(88);
			line-height: p2v(88);
			color: #fff;
			background: rgba(6, 1, 1, 0.6);
			&.active {
				color: $blue-color;
				background: rgba(6, 1, 1, 0.8);
			}
		}
	}
	.draw-grid-border {
		width: 100vw;
		height: p2v(516);
		background-color: rgba($color: #000000, $alpha: 0.8);
		position: absolute;
		top: 0;
		display: none;
		@keyframes slideDown {
			from {
				transform: translateY(-1 * p2v(516));
			}
			to {
				transform: translateY(0);
			}
		}
		animation: slideDown 0.5s linear;
		.draw-grid {
			width: 100%;
			height: 100%;
			@include min_flex(column, space-evenly, center);
			li {
				text-align: center;
				a {
					color: #fff;
					text-decoration: none;
				}
				&.active {
					a {
						color: $pink-color;
					}
				}
			}
		}
	}
}
.container {
	width: 100vw;
	.banner,
	.solution,
	.server-border,
	.protect-border,
	.manage-border,
	.system-display,
	.classic-case-border,
	.client-presentation {
		display: none;
	}
	.product-service {
		padding-top: p2v(60);
		.header {
			@include min_header();
		}
		.grid-box {
			width: 100vw;
			padding-top: p2v(60);
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			.grid-content {
				width: p2v(375);
				text-align: center;
				margin-top: p2v(20);
				img {
					width: p2v(124);
					height: p2v(124);
				}
				.grid-text {
					@extend %min_grid-text;
					margin-top: p2v(20);
					padding: 0 p2v(30);
				}
			}
		}
	}
	.company-introdution-boder {
		margin-top: p2v(80);
		background-image: url('../assets/image/banner/公司介绍.png');
		background-size: 100% 100%;
		width: 100%;
		height: p2v(310);
		.company-introdution {
			position: relative;
			clear: both;
			text-align: center;
			// height: calc(100% - p2v(50));
			color: #fff;
			padding-top: p2v(50);
			.header {
				@include min_header(#fff, #fff);
			}
			.grid-text {
				@extend %min_grid-text;
				color: #fff;
				margin-top: p2v(20);
				padding: 0 p2v(64);
			}
		}
	}
	.digital-resources,
	.digital-communication,
	.digital-services {
		padding-top: p2v(60);
		.header {
			@include min_header();
		}
		.digital-icon {
			padding-top: p2v(70);
			text-align: center;
			img {
				width: p2v(90);
				height: p2v(90);
			}
		}
		.content-text {
			padding: p2v(40) p2v(40) 0;
			font-size: 0.8rem;
			color: #999;
			line-height: p2v(36);
		}
		.digital-image {
			padding-top: p2v(20);
			width: 100vw;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.min-system-display {
		padding-top: p2v(60);
		.header {
			@include min_header();
		}
		.content {
			padding: p2v(40) p2v(40);
			.title {
				font-size: 1.5rem;
				color: #333333;
			}
			.explain {
				font-size: 0.8rem;
				color: #888888;
			}
			.text {
				font-size: 0.8rem;
				padding-top: p2v(30);
				line-height: p2v(36);
				color: #999999;
			}
		}
		.min-swiper-container {
			position: relative;
			overflow: hidden;
			.swiper-slide {
				width: 100vw;
				img {
					width: 100%;
				}
			}
		}
	}
	.min-classic-case {
		width: 100vw;
		padding-top: p2v(60);
		.header {
			@include min_header();
		}
		.content {
			padding: p2v(50) p2v(40);
			.image {
				img {
					width: 100%;
				}
			}
			.text {
				margin-top: p2v(40);
				.title {
					font-weight: bold;
					color: #333;
				}
				.explain {
					margin-top: p2v(20);
					font-size: 0.8rem;
					line-height: p2v(36);
					color: #999999;
				}
				.date {
					color: #888;
					text-align: right;
					font-size: 0.8rem;
				}
			}
		}
	}
	.min-client-presentation {
		padding-top: p2v(60);
		.header {
			@include min_header();
		}
		.client-list {
			padding: p2v(20) p2v(40);
			@include min_flex(row, space-around, flex-start);
			flex-wrap: wrap;
			div {
				width: 30%;
				height: p2v(50);
				line-height: p2v(50);
				margin-top: p2v(30);
				border: 1px solid #dcdcdc;
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}
}
footer {
	display: none;
}
.min-contact-us {
	margin-top: p2v(20);
	background-color: #333333;
	color: #999999;
	height: p2v(250);
	@include min_flex(row, space-between, normal);
	padding: 0 p2v(30);
	.grid-box {
		@include min_flex(column, space-evenly, flex-start);
		padding: 0 p2v(20);
		.title {
			color: #fff;
			font-size: 0.8rem;
		}
		.text {
			font-size: 0.8rem;
			img {
				width: p2v(110);
				height: p2v(110);
			}
		}
	}
}
